<template>
    <section>
        <el-select v-model="emailType" placeholder="Select Type" style="float : right; margin-bottom: 5px; width : 150px" clearable @change="fetchEntityDataEmails(true)">
            <el-option label="Notification" value="Notification"></el-option>
            <el-option label="Automation" value="Automation"></el-option>
        </el-select>
        <div class="vue-data-table-default">
            <data-tables-server :data="entityDataEmails" border :total="total" :current-page.sync="currentPage"
                :page-size="pageSize" :pagination-props="paginationProps" @query-change="loadData"
                v-loading="loading">
                <el-table-column label="From" prop="from" width="250">
                </el-table-column>
                <el-table-column label="To" prop="to" width="250">
                </el-table-column>
                <el-table-column label="Sent On" prop="sent_on" width="120">
                </el-table-column>
                <el-table-column label="Current Status">
                    <template slot-scope="scope">
                        <el-tooltip :content="'Timestamp : ' + scope.row.timestamp" placement="top">
                            <span>{{ statusMap[scope.row.status] }}</span>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column label="Type">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.type == 'Notification'">
                            <i class="el-icon-position"></i> Notification
                        </el-tag>
                        <el-tag v-else-if="scope.row.type == 'Automation'" type="success">
                            <i class="el-icon-setting"></i> Automation
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="Content">
                    <template slot-scope="scope">
                        <a @click="openPreview(scope.$index)">
                            <i class="el-icon-view" style="color : #409EFF"></i>
                            <span style="color : #409EFF; cursor : pointer;"> Preview</span>
                        </a>
                    </template>
                </el-table-column>
            </data-tables-server>
        </div>
        <dialog-component 
            :visible="openPreviewDialog" 
            :containerMinWidth="'50%'" 
            :containerMaxWidth="'85%'" 
            @before-close="openPreviewDialog=false"
            :isShowFooter="false"
            :containerMaxHeight="'95%'"
            :title="'Email Preview'">
            <div style="margin-top:10px;">
            <p><b>From : </b>{{ previewContent.from }}</p>
            <p style="margin-top: 5px !important;"><b>To : </b>{{ previewContent.to }}</p>
            <p style="margin-top: 5px;"><b>Subject : </b>{{ previewContent.subject }}</p>
            </div>
            <el-divider></el-divider>
            <el-scrollbar wrap-style="max-height: 400px; overflow-x: auto; overflow-y: auto; scrollbar-width:thin;">
            <div class="content">
                <div v-if="previewContent.content" v-html="previewContent.content">
                </div>
                <div v-else>
                    Content not available
                </div>
            </div>
            </el-scrollbar>
        </dialog-component>
    </section>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'entities-data-emails',
    components: {},
    data() {
        return {
            entityDataEmails: [],
            openPreviewDialog: false,
            previewContent: {
                content: '',
                from: '',
                to: '',
                subject: ''
            },
            total: 0,
            currentPage: 1,
            emailType: '',
            pageSize: 5,
            loading: false,
            statusMap : {
                'open' : 'Opened',
                'bounce' : 'Undelivered',
                'processed' : 'Processed',
                'delivered' : 'Delivered',
                'click' : 'Clicked'
            }
        }
    },
    async mounted() {
        await this.fetchEntityDataEmails()
    },
    computed: {
        ...mapGetters("entityEmailTemplate", ["getEmailLogs"]),
        paginationProps() {
            return {
                pageSizes: this.pageSizes || [5, 10, 20],
            };
        },
    },
    methods: {
        loadData(data){
            this.currentPage = data.page
            this.pageSize = data.pageSize
            this.fetchEntityDataEmails()
        },
        async fetchEntityDataEmails(fromChange = false) {
            this.loading = true
            this.entityDataEmails = []
            this.currentPage = fromChange ? 1 : this.currentPage
            let entityDataId = this.$route.query.dataId
            if(entityDataId == undefined){
                this.loading = false
                return
            }
            let params = {
                id: entityDataId,
                page: this.currentPage || 1,
                limit: this.pageSize || 5,
                timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone
            }
            if (this.emailType !== '') {
                params['type'] = this.emailType
            }
            await this.$store.dispatch("entityEmailTemplate/fetchEmailLogs", params)
            if (this.getEmailLogs && this.getEmailLogs.data && this.getEmailLogs.data.data && this.getEmailLogs.data.data.length) {
                this.entityDataEmails = this.getEmailLogs.data.data
                this.total = this.getEmailLogs.data.total
            }
            this.loading = false
        },
        openPreview(index) {
            this.openPreviewDialog = true
            let content = this.entityDataEmails[index]
            // let emailContent = Buffer.from(content.content, 'base64').toString('utf-8')
            this.previewContent = {
                from: content.from,
                to: content.to,
                subject: content.subject,
                content: content.content
            }
        }
    }
}
</script>
<style scoped>
.el-scrollbar__wrap {
    overflow-y: auto; 
    overflow-x: auto;
    scrollbar-width: thin;
    max-height: 400px;
    padding: 10px;
    margin-bottom: 20px;
}
.content {
    white-space: nowrap;
    padding: 10px;
}
.el-scrollbar__wrap el-scrollbar__wrap--hidden-default {
    overflow-x: auto !important;
    overflow-y: auto !important;
    scrollbar-width: thin !important;
    max-height: 400px;
}
</style>